import './styles.css';

import PartySocket from 'partysocket';

declare const PARTYKIT_HOST: string;

const url = window.location.search.split('&');

const room = url[1].split('=')[1];

// Let's append all the messages we get into this DOM element
const output = document.getElementById('app') as HTMLDivElement;

// Helper function to add a new line to the DOM
function add(text: string) {
	output.appendChild(document.createTextNode(text));
	output.appendChild(document.createElement('br'));
}

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
	host: PARTYKIT_HOST,
	room,
	id: uuidv4() + '--' + url[0].split('=')[1],
	// id: "arjun" + room + "@terra.do",
});

function uuidv4() {
	return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
		(
			+c ^
			(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
		).toString(16),
	);
}

// You can even start sending messages before the connection is open!
conn.addEventListener('message', event => {
	console.log(event);

	add(`Received -> ${event.data}`);
});

// Let's listen for when the connection opens
// And send a ping every 2 seconds right after
conn.addEventListener('open', () => {
	add('Connected!');
	add('Sending a ping every 2 seconds...');

	add('abc' + room);

	conn.send(
		JSON.stringify({
			action: 'connection',
			user: {
				name: 'arjun',
				email: url[0].split('=')[1],
			},
		}),
	);
});
